import * as React from 'react'

export const WebDevelopment: React.FC = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    viewBox='0 0 210 210'
    xmlSpace='preserve'
  >
    <style>
      {
        '.st0,.st2{fill:none;stroke:var(--color-badge-text);stroke-width:1.25;stroke-miterlimit:10}.st2{stroke:#3c388f;stroke-miterlimit:9.9999}.st3{enable-background:new;fill:var(--color-badge-text)}.st4{fill:none;stroke:var(--color-badge-text);stroke-width:1.25;stroke-linecap:round;stroke-linejoin:round}.st9Web{fill:var(--color-badge-text);stroke:var(--color-badge-text)}'
      }
    </style>
    <path
      className='st0'
      d='M136.6 48.6c35.3 15.8 51.1 57.2 35.4 92.5s-57.2 51.1-92.5 35.4S28.4 119.3 44.1 84c2.3-5 5.1-9.8 8.5-14.2'
    />
    <path
      d='M136.6 96.7H90.9c-.4 0-.7.3-.7.7v31.3c0 .4.3.7.7.7h17.3v6h-4.4c-.4 0-.7.3-.7.7s.3.7.7.7h19.7c.4 0 .7-.3.7-.7s-.3-.7-.7-.7h-8.6v-6h21.5c.4 0 .7-.3.7-.7V97.3c.1-.3-.2-.6-.5-.6z'
      fill='#a5a5ff'
    />
    <path
      transform='rotate(-45 108.97562657 73.46819548)'
      className='st2'
      d='M106.5 71h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 69.61910417 112.94206705)'
      className='st2'
      d='M67.2 110.5h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 148.3894968 112.84427042)'
      className='st2'
      d='M145.9 110.4h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 108.9527292 152.17727957)'
      className='st2'
      d='M106.5 149.7h4.9v4.9h-4.9z'
    />
    <path
      d='m3.4 107 .3-1.9 9.5-.9c1-.1 2.5-.2 2.5-.2s-1.5-.5-2.4-.9l-8.8-3.8.3-1.9 9.5-1.2c1-.1 2.5-.2 2.5-.2s-1.5-.6-2.3-.9l-8.9-3.7.3-1.8 13.6 5.9-.2 1.7-10.4 1.1c-.9.1-2.2.2-2.2.2s1.3.5 2.1.8l9.6 4.2-.3 1.8L3.4 107zM8.2 81.9l3.4-9.5 1.5.5-2.8 7.8 4.3 1.5 2.5-7.1 1.4.5-2.5 7.2 4.7 1.7 2.9-8 1.6.6-3.5 9.7-13.5-4.9zM15.1 64.9l3-5.3c.7-1.3 1.7-2.1 2.8-2.4.8-.2 1.7-.1 2.6.4 1.4.8 1.8 2 1.5 3.5h.1c1.1-1.2 2.8-1.7 4.4-.8 1 .5 1.6 1.3 1.9 2.3.3 1.1.2 2.4-.6 3.8L27.5 72l-12.4-7.1zm8.2-2c.9-1.5.6-2.8-.6-3.6-1.2-.7-2.4-.3-3.2 1.1l-2.1 3.7 4 2.3 1.9-3.5zm5.9 2.8c.5-.9.7-1.7.5-2.3-.2-.6-.5-1-1.1-1.4-1.5-.9-2.9-.2-3.8 1.3l-2.2 3.8 4.4 2.5 2.2-3.9zM30.3 42.7 34 39c3.1-3.1 6.6-2.4 9.6.6s3.5 6.6.4 9.6l-3.6 3.6-10.1-10.1zm12.6 5.4c2.4-2.4 1.8-4.9-.6-7.3-2.4-2.4-4.9-3.2-7.2-.8l-2.4 2.4 7.9 7.9 2.3-2.2zM44.9 29.4l8.3-5.7.9 1.3-6.8 4.7 2.6 3.7 6.2-4.3.9 1.3-6.2 4.3 2.8 4.1 7-4.8.9 1.4-8.5 5.9-8.1-11.9zM59.3 20.1l1.8-.8 7.6 8.2C69.2 28 70 29 70 29s-.2-1.3-.3-2l-1.2-11 1.7-.8 1.4 15.1-1.8.8-10.5-11zM77.3 12.8l9.8-2.5.4 1.5-8 2.1 1.1 4.4 7.3-1.9.4 1.5-7.3 1.8 1.3 4.9 8.3-2.1.4 1.6-10 2.6-3.7-13.9zM95.2 9l1.8-.2 1 12.6 7.5-.6.1 1.7-9.3.8L95.2 9zM110 15.2c.4-4.3 3.4-7.2 7.7-6.8 4.3.4 6.7 3.8 6.3 8.1-.4 4.3-3.4 7.2-7.7 6.8-4.2-.4-6.6-3.9-6.3-8.1zm12.2 1.1c.3-3.4-1.3-6.1-4.6-6.4-3.4-.3-5.4 2.1-5.7 5.4-.3 3.4 1.3 6 4.6 6.3 3.4.4 5.4-2 5.7-5.3zM132.7 11.2l5.7 1.7c1.4.4 2.4 1.2 2.9 2.3.4.8.5 1.8.2 2.9-.7 2.5-2.6 3.6-5.6 2.7l-3.7-1.1-1.7 5.8-1.7-.5 3.9-13.8zm-.2 7 3.6 1.1c1.9.6 3.1-.1 3.6-1.7.5-1.7-.3-2.8-2-3.3l-3.7-1.1-1.5 5zM150 16.9l2 1.1-1.4 12.7 9.6-8.4 2.1 1.1-6.5 12.6-1.5-.8 5.4-10.4s-.9.8-1.4 1.3l-8.1 7-1.2-.6 1.1-10.6c.1-.8.3-1.9.3-1.9L145 30.4l-1.4-.7 6.4-12.8zM169.7 28.3l7.9 6.4-1 1.2-6.5-5.2-2.8 3.5 5.9 4.8-1 1.2-5.9-4.8-3.2 3.9 6.7 5.4-1 1.3-8.1-6.5 9-11.2zM183.6 40.5l1.2 1.4-3.3 11.4c-.2.8-.7 2-.7 2l9-7.7 1.1 1.3-10.9 9.4-1.2-1.4 3.3-11.5c.2-.8.7-2 .7-2l-8.9 7.6-1.1-1.3 10.8-9.2zM195.3 55.4l5.7 9.7-1.4.8-2.4-4.1-10.9 6.4-.9-1.5 10.9-6.4-2.4-4.1 1.4-.8z'
      className='st3'
    />
    <path
      className='st0'
      d='M198 111.8c.5 49.7-39.4 90.4-89.1 90.9-36.4.4-69.5-21.2-83.7-54.8'
    />
    <path
      className='st9Web'
      transform='rotate(-14.164 195.29371169 90.61704348)'
      d='M193.3 88.6h4v4h-4z'
    />
    <path
      className='st9Web'
      transform='matrix(.9842 -.1773 .1773 .9842 -22.4756 5.4753)'
      d='M17.4 126.5h4v4h-4z'
    />
    <path d='m110.8 101.7-.6-.2c-.1 0-.3 0-.3.2l-3.5 12.2v.2c0 .1.1.1.2.1l.6.2h.2c.1 0 .1-.1.1-.2L111 102v-.2c-.1 0-.2 0-.2-.1zM105.9 105.4c0-.1 0-.1-.1-.2l-.4-.5c0-.1-.1-.1-.2-.1s-.1 0-.2.1l-3.6 3.1c-.1.1-.1.3 0 .4l3.6 3.1s.1.1.2.1.1 0 .2-.1l.5-.5s.1-.1.1-.2 0-.1-.1-.2L103 108l2.9-2.4v-.2zM115.8 107.8l-3.6-3.1c-.1-.1-.3-.1-.4 0l-.4.5s-.1.1-.1.2 0 .1.1.2l2.9 2.4-2.9 2.4c-.1 0-.1.1-.1.2s0 .1.1.2l.4.5c.1.1.3.1.4 0l3.6-3.1c.1-.2.1-.3 0-.4z' />
    <path className='st4' d='M84 90.4h49.9V126H84z' />
    <path
      className='st4'
      d='M86.1 92.5h45.7v31.4H86.1zM106.2 126h5.5v8.6h-5.5zM96.9 134.6h24'
    />
  </svg>
)
export default WebDevelopment
