import InvertingImageSection from '@components/InvertingImageComponent'
import Section from '@components/SectionComponent'
import Spacer from '@components/SpacerComponent'
import { Heading, Highlight } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import React from 'react'

// Assets
import AgileKinetic from '@assets/images/agile-kinetic.png'
import Breacon from '@assets/images/brecon-wind-turbine.jpg'
import CoProduction from '@assets/images/co-production-collective.png'
import Fabric from '@assets/images/fabric-kids.png'
import BreaconWebP from '@assets/images/webp/brecon-wind-turbine.webp'
import CoProductionWebp from '@assets/images/webp/co-production-collective.webp'
import FabricWebp from '@assets/images/webp/fabric-kids.webp'

import { contentfulImagePaths } from '../../../assets/projectImages/contentfulAssetPaths'

type OurWorkProps = {}

const OurWork: React.FC<OurWorkProps> = () => {
  return (
    <Section>
      <Grid.Item column='1 / -1'>
        <Heading centered>
          Our work <Highlight>in the wild</Highlight>
        </Heading>
        <Spacer bottom='xl2' />
      </Grid.Item>
      <InvertingImageSection
        data={[
          {
            id: '13',
            heading: 'Raising the Standards in Social Care',
            caption: 'Fabric',
            paragraph:
              'A simple to use web app that allows care workers to spend more time with kids.',
            image: {
              alt: 'Children smiling',
              portrait: true,
              src: FabricWebp,
              sources: [
                { srcSet: FabricWebp, type: 'image/webp' },
                { srcSet: Fabric, type: 'image/png' },
              ],
            },
            to: '/case-study/internal-web-app/fabric',
          },
          {
            id: '14',
            heading: 'Powering the Climate Cause',
            caption: 'Amber Energy',
            paragraph:
              'Empowering university students to change their habits and save energy.',
            image: {
              alt: 'Wind turbine in the breacon forest',
              portrait: true,
              src: BreaconWebP,
              sources: [
                { srcSet: BreaconWebP, type: 'image/webp' },
                { srcSet: Breacon, type: 'image/jpeg' },
              ],
            },
            to: '/case-study/web-app/the-student-energy-project',
          },
          {
            id: '15',
            heading:
              'A new brand identity and website for the co-production movement',
            caption: 'Co-Production Collective',
            paragraph:
              'Fueling the international co-production movement with a new identity, accessible website and community area.',
            image: {
              alt: 'Group of people around the table',
              src: CoProductionWebp,
              portrait: true,
              sources: [
                { srcSet: CoProductionWebp, type: 'image/webp' },
                { srcSet: CoProduction, type: 'image/jpeg' },
              ],
            },
            to: '/case-study/web-development/co-production-collective',
          },
          {
            id: '16',
            heading: 'Developing the next generation of healthtech apps',
            caption: 'Agile Kinetic',
            paragraph:
              'Creating a health tech app that’s just like a physiotherapist in your pocket.',
            image: {
              alt: 'Person using a mobile phone',
              portrait: true,
              src: AgileKinetic,
              sources: [
                // { srcSet: BreaconWebP, type: 'image/webp' },
                { srcSet: AgileKinetic, type: 'image/png' },
              ],
            },
            to: '/case-study/health-tech-app/agile-kinetic',
          },
          {
            id: '17',
            caption: 'Town Square',
            heading:
              'Internal and external booking systems, apps, CTO input and much more',
            paragraph:
              'Multiple differing tech platforms to support a fast-growing co-working company.',
            image: {
              alt: '',
              portrait: true,
              src: contentfulImagePaths['town-sq-1'],
            },
            to: '/case-study/web-app/town-square',
          },
          {
            id: '18',
            caption: 'miFuture',
            heading:
              'Digital products supporting school-leavers to find their vocation.',
            paragraph:
              'Digital products to nudge school leavers towards higher- skilled future-focussed careers.',
            image: {
              alt: '',
              portrait: true,
              src: contentfulImagePaths['mifuture-1'],
            },
            to: '/case-study/web-app/mifuture',
          },
          {
            id: '19',
            caption: 'Trust the Experts',

            heading:
              'Educating school pupils on how expert knowledge can influence crucial decision-making processes.',
            paragraph:
              'A bespoke web-based platform that hands you the decisions that impact millions of people',
            image: {
              alt: '',
              portrait: true,
              src: contentfulImagePaths['tte-1'],
            },
            to: '/case-study/web-app/trust-the-experts',
          },
          {
            id: '20',
            caption: 'Exhibit C',
            heading: 'The world’s first carbon data tracking app.',
            paragraph:
              'Building the app that helps community organisations to record their carbon savings and reward them',
            image: {
              alt: '',
              portrait: true,
              src: contentfulImagePaths['exhibit-c-1'],
            },
            to: '/case-study/web-app/exhibit-c',
          },
          {
            id: '21',
            caption: 'My Discombobulated Brain',
            heading:
              'Facilitating mental health support when in public spaces.',
            paragraph:
              'Helping the 30,000 students of Cardiff access a safe space when experiencing a panic or anxiety attack in public.',
            image: {
              alt: '',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/14iVily2O6jMW3SAQVBi9F/bf95187e27d0b1bb8d8407462b831d8c/MDB-logo.png',
            },
            to: '/case-study/web-app/my-discombobulated-brain',
          },
          {
            id: '22',
            caption: 'Benthyg Cymru',
            heading: 'Big Lemon powers Benthyg library of things network.',
            paragraph:
              'A co-produced bespoke new website that makes borrowing easier and supports the development of more libraries around the country.',
            image: {
              alt: '',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/1RmB9K4p7ICeWrZPQRFo9D/39943df9f2e737d19ae47a2b17d4352f/Work.png',
            },
            to: '/case-study/web-app/benthyg-cymru',
          },
          {
            id: '23',
            caption: "Minty's Gig Guide",
            heading: 'Big Lemon creates Minty’s Gig Guide online',
            paragraph:
              'Powering 100,000 more gig-goers for Cardiff indie venues.',
            image: {
              alt: '',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/1nZN18D0gST4ekWfQDGIfR/d1aaaa0d6c206fff22e2530b80ef2f5a/minty-desktop.png',
            },
            to: '/case-study/web-app/mintys-gig-guide',
          },
          {
            id: '24',
            caption: 'The Bike Lock',
            heading: 'Social enterprise cafe, The Bike Lock, launches new app.',
            paragraph:
              'The Bike Lock is a new social enterprise offering secure bike storage, showers, coffee and workspace in Cardiff city centre.',
            image: {
              alt: '',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/1XjdZ600Q7wfb994rkUph3/37159b1820ab0b37bf5ade3e658e88db/bike-lock-interior.png',
            },
            to: '/case-study/web-app/bike-lock',
          },
          {
            id: '25',
            caption: 'The Midwifery Unit Self Assessment',
            heading: 'A new online home for the MUSA framework',
            paragraph:
              'The Midwifery Unit Self Assessment (MUSA) project provides a structured framework to guide and support the implementation, scale-up and continuous improvement of midwifery units in the United Kingdom and Europe.',
            image: {
              alt: 'The Midwifery Unit Self Assessment Website Screenshot',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/6s05xx8u38XpDQreG6sWz2/3d0f97bf47b9e1314ab3b4e52495ce1f/02.jpg?w=1600&q=50',
            },
            to: '/case-study/web-app/musa',
          },
          {
            id: '26',
            caption: 'My emoji – Fy emoji',
            heading: 'Taking the paper-based evaluation tool digital',
            paragraph:
              'A quick and inclusive measure of mood, current experience, and wellbeing changes.',
            image: {
              alt: 'My Emoji',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/5L0pt3gh1QhpKwSnxKmnDE/d16ad3f71cc959bd3480b698eb4b374d/myemoji3.png',
            },
            to: '/case-study/web-app/myemoji',
          },
          {
            id: '27',
            caption: 'Ignaz Technology',
            heading: 'Digital monitoring technology in the NHS',
            paragraph:
              'An easy-to-use digital monitoring app for the treatment and prevention of pressure ulcers, reducing pain and deterioration of patients’ health while at the same time saving valuable NHS resources.',
            image: {
              alt: 'Ignaz',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/76qG2q5GupB276ai8w3LzS/a56f5341f64561393a73bc04bd136a17/ignaz_ipad.png',
            },
            to: '/case-study/native-app/ignaz',
          },
          {
            id: '28',
            caption: 'CAFOD',
            heading: 'Using technology to support children’s learning',
            paragraph:
              'CAFOD, part of one of the largest aid networks in the world, are reaching out to young people to be the change they want to see in their communities and worldwide.',
            image: {
              alt: 'Cafod',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/3DZtIt9zUSpywQW1aO1zE/3d0a1bcdbf96e07c5289b924f463b569/cafod-tablet-view-1.png',
            },
            to: '/case-study/web-app/cafod',
          },
          {
            id: '29',
            caption: 'Superchoir',
            heading: 'Saving dozens of monthly hours by automating a choir',
            paragraph:
              "Taking a deep dive into Superchoir's processes and creating innovative solutions",
            image: {
              alt: 'Superchoir',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/3hifM9xm8he4bQZIg21O7l/68d85c25c0c919a560891f01021d50a8/macbook-superchoir.png',
            },
            to: '/case-study/internal-tool/superchoir',
          },
          {
            id: '30',
            caption: 'Compost London',
            heading: 'Helping Voluntary and Community groups grow and thrive',
            paragraph:
              'A co-produced bespoke new website and refresh of their brand identity',
            image: {
              alt: 'Compost London',
              portrait: true,
              src: '//images.ctfassets.net/7ajoku588qiq/k9vMpFbBlPHn5jXM2yw8t/6acb0809a08514be140de7600cdbb68b/Twitter_02.png',
            },
            to: '/case-study/website/compost-london',
          },
        ].reverse()}
      />
    </Section>
  )
}

export default OurWork
