import SectionComponent from '@components/SectionComponent'
import React from 'react'
import Grid from '@components/ViewGridComponent'
import { ImageComponent as Image } from '@components/ImageComponent'
import ImageSpacerComponent from '@components/ImageSpacerComponent'
import { Paragraph, Heading, Highlight } from '@components/Typography'
import SpacerComponent from '@components/SpacerComponent'
import TechWithPurposeBadge from '@components/BadgeComponent/TechWithPurpose'
import { FadeAndRotate, FadeIn } from '@components/Animations'
import { useIsMobile } from '../../../hooks'

const TechWithPurpose = () => {
  const isMobile = useIsMobile()
  return (
    <SectionComponent>
      <Grid.Item column={isMobile ? '5 / 9' : '1 / 4'}>
        <FadeAndRotate direction='middle'>
          <TechWithPurposeBadge />
        </FadeAndRotate>
        <SpacerComponent bottom={isMobile ? 'lg' : '0'} />
      </Grid.Item>
      <Grid.Item column={isMobile ? '1 / 13' : '6 / 13'}>
        <FadeIn>
          <Heading>
            We work with you to build <Highlight>products that stick</Highlight>
            , so your end-users benefit.
          </Heading>
          <SpacerComponent bottom={'lg'} />
          <Paragraph size='md'>
            We work with our clients to co-create digital app and web
            development projects that people love using, developed with
            inclusion in mind.
          </Paragraph>
          <SpacerComponent bottom={'sm'} />
          <Paragraph size='md'>
            It might seem obvious, but the way to ensure your product is used
            and creates impact is to get your audience in on the action.
          </Paragraph>
        </FadeIn>
      </Grid.Item>
      <SpacerComponent bottom={isMobile ? 'xl2' : 'xl3'} />
    </SectionComponent>
  )
}

export default TechWithPurpose
