import SeoComponent from '@components/SeoComponent'
import { darkPurpleTheme, paleBlueTheme, whiteTheme } from '@src/themes'
import {
  ApproachHero,
  OurWork,
  Solutions,
  TechWithPurpose,
} from '@src/views/ApproachSections'
import Quotes from '@views/SharedSections/Quotes/Quotes'
import React from 'react'
import { ThemeProvider } from 'styled-components'

type ApproachProps = {}

const Approach: React.FC<ApproachProps> = () => {
  return (
    <>
      <SeoComponent
        title='Big Lemon | App and Web Development Projects with Purpose'
        description='We partner with organisations and develop app and web development projects that stick, that people love using, and are developed inclusively.'
      />
      <ThemeProvider theme={darkPurpleTheme}>
        <ApproachHero />
      </ThemeProvider>
      <ThemeProvider theme={paleBlueTheme}>
        <TechWithPurpose />
      </ThemeProvider>
      <ThemeProvider theme={darkPurpleTheme}>
        <OurWork />
      </ThemeProvider>
      <ThemeProvider theme={whiteTheme}>
        <Solutions />
      </ThemeProvider>
      <ThemeProvider theme={paleBlueTheme}>
        <Quotes />
      </ThemeProvider>
    </>
  )
}

export default Approach
