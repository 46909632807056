export const contentfulImagePaths = {
  'exhibit-c-1':
    '//images.ctfassets.net/7ajoku588qiq/37Y6UgJHe5vjD82s9IFDHq/07aecfe1dbfd5ef73ea4f0304ad72236/echibit-c-1.png',
  'exhibit-c-2':
    '//images.ctfassets.net/7ajoku588qiq/uUeGpcLctSy8FOTMO08ZH/387da929416244e0dbb6a1e3b41017a1/exhibit-c-2.png',
  'exhibit-c-3':
    '//images.ctfassets.net/7ajoku588qiq/1KaPCGJqBeKofViJ8bbJUf/10b4b214b7bdf6c04ea37a7f5e358ac5/exhibit-c-3.jpg',
  'mifuture-1':
    '//images.ctfassets.net/7ajoku588qiq/5SPISFqE05ZhCOchQfuDmH/ed789a8ea1af4ded3ed2f39ef5303120/mifuture-1.png',
  'mifuture-2':
    '//images.ctfassets.net/7ajoku588qiq/5wQvOx3M4Vg29OZjp8PnZK/db3835d1e2cbbbdcc0b4c7926f6f23d4/mifuture-2.png',
  'mifuture-3':
    '//images.ctfassets.net/7ajoku588qiq/7uhhaQmoUSvM1x9LFUh7bH/fec8822738bb2085c5b365cc1214a658/mifuture-3.png',
  'mifuture-4':
    '//images.ctfassets.net/7ajoku588qiq/58gdj2xJD5BF9Vp0AVWvtD/66e4ca2f556671fe1f4e6df428ec8da1/mifuture-4.jpg',
  'mifuture-5':
    '//images.ctfassets.net/7ajoku588qiq/4huMl84KDbCnMc2Q6ADR3E/29f63ce8a2e7854e433e0be5cf463d2b/mifuture-5.png',
  'town-sq-1':
    '//images.ctfassets.net/7ajoku588qiq/460zeWPkYExqfuswOEtMB4/40f40770ed0767ed9f132358e2fc19f8/town-sq-1.png',
  'town-sq-2':
    '//images.ctfassets.net/7ajoku588qiq/3eCbJZNUTkFibAQGK4K7dt/1609e176bef7fd4ac2f15658496a3d2e/town-sq-2.png',
  'town-sq-3':
    '//images.ctfassets.net/7ajoku588qiq/45ygSoyg84NwXU3gFUlM14/9432d9b2606326023fb9f530ab299278/town-sq-3.png',
  'tte-1':
    '//images.ctfassets.net/7ajoku588qiq/6BRdB4lJ5hTtv8RQZx6wDq/ffaad212a4f504688235096069a1d448/tte-1.png',
  'tte-2':
    '//images.ctfassets.net/7ajoku588qiq/NSxQWEkW1tRgYyC3teFXR/1f474eb2dce45a23b7dfb1ca380dbb36/tte-2.png',
}
