import * as React from 'react'

export const CTOServices: React.FC = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    viewBox='0 0 210 210'
    xmlSpace='preserve'
  >
    <style>
      {
        '.ctoText{fill:var(--color-badge-text)}.st1cto.logoCto{fill:var(--color-badge-text);};.st0cto{enable-background:new;fill:var(--color-badge-text)}.st1cto,.st2cto{fill:none;stroke:var(--color-badge-text);stroke-width:1.25;stroke-miterlimit:10}.st2cto{stroke:#3c388f;stroke-miterlimit:9.9999}.st3cto{fill:#a5a5ff}.st4cto{fill:none;stroke:var(--color-badge-text);stroke-miterlimit:10}.st6cto{fill:var(--color-badge-text)}'
      }
    </style>
    <path
      d='M14.8 107.5c-4.2-.5-7.1-3.4-6.6-7.6.4-3.3 2.5-5.1 5.2-5.1l-.2 1.7c-1.8.1-3.2 1.4-3.5 3.6-.4 3.1 1.9 5.2 5.3 5.6 3.5.4 6-1.2 6.4-4.2.3-2.7-1.3-4.2-3.4-4.6l.2-1.8c1.4.2 2.9 1.1 3.8 2.2.9 1.1 1.2 2.6 1 4.4-.6 4.1-3.9 6.4-8.2 5.8zM10.5 87.7l3.3-10.8 1.5.5-1.4 4.6 12.2 3.7-.5 1.7-12.2-3.7-1.4 4.5-1.5-.5zM22.4 74.2c-3.8-2-5.4-5.8-3.4-9.6 2-3.8 6-4.8 9.8-2.8 3.8 2 5.4 5.8 3.4 9.6-1.9 3.9-6 4.8-9.8 2.8zM28 63.4c-3-1.5-6.1-1.1-7.6 1.9s-.1 5.8 2.9 7.3 6.1 1.1 7.6-1.9.1-5.7-2.9-7.3zM38.4 52.4l1.2-1.3c1.9 1.5 3.9 1.2 5.7-.7 1.3-1.5 1.7-3.1.3-4.3-1.4-1.3-2.9-.4-5.1 1.1-2.1 1.5-4.1 2.6-6.1.7-1.7-1.6-1.4-4 .5-6.1 2.4-2.6 4.8-2.5 7-.9l-1.2 1.2c-1.7-1.2-3.3-.9-4.7.6-1.3 1.4-1.6 2.9-.5 3.9 1.2 1.1 2.6.3 4.4-1 2.3-1.7 4.5-3.1 6.9-.9 2 1.8 1.6 4.4-.5 6.7-2.7 3-5.6 2.8-7.9 1zM45.2 32.3l8.1-6.1 1 1.3-6.6 5 2.7 3.6 6-4.5.9 1.2-6 4.5 3 4 6.8-5.1 1 1.3-8.3 6.2-8.6-11.4zM60.3 21.9l6-3c2.2-1.1 4.5-.5 5.5 1.6.8 1.6.7 3-.6 4.3v.1c1.5-.2 2.4.5 3.5 2.3 1.2 2 1.7 2.4 2 2.4v.1l-1.7.8c-.4-.1-.7-.6-1.9-2.6-1.1-1.8-2.2-2.1-3.9-1.2l-3.6 1.8 2.7 5.4-1.6.8-6.4-12.8zm4.7 5.2 3.8-1.9c1.8-.9 2.2-2.2 1.5-3.7-.8-1.6-1.9-2-3.8-1l-3.9 2 2.4 4.6zM76.5 14.3l1.9-.6 6.4 9.2c.4.6 1.1 1.7 1.1 1.7v-2l.3-11.2 1.8-.5-.7 15.1-1.9.6-8.9-12.3zM94.9 9.7l1.8-.3 2.2 14.1-1.8.3-2.2-14.1zM104.5 15.5c0-4.2 2.4-7.5 6.7-7.5 3.3 0 5.4 1.9 5.8 4.5h-1.8c-.4-1.8-1.8-3-4.1-3-3.1 0-4.9 2.6-4.8 6 0 3.5 2 5.8 5 5.8 2.7 0 4-1.9 4.1-4h1.8c0 1.4-.7 3-1.7 4s-2.4 1.5-4.3 1.5c-4 .1-6.6-3-6.7-7.3zM125.1 9l10 1.7-.3 1.6-8.2-1.4-.8 4.5 7.4 1.3-.3 1.5-7.4-1.3-.9 4.9 8.4 1.5-.3 1.6-10.2-1.8L125.1 9zM138.8 21.4l1.7.6c-.6 2.4.4 4 2.9 5 1.9.7 3.5.3 4.1-1.4.7-1.8-.8-2.9-3-4.3s-4-2.8-3-5.3c.8-2.2 3.1-2.9 5.9-1.9 3.4 1.2 4.1 3.4 3.6 6l-1.6-.6c.4-2-.4-3.4-2.4-4.1-1.8-.7-3.3-.3-3.8 1.1-.6 1.5.8 2.5 2.7 3.6 2.4 1.5 4.6 3 3.5 6-.9 2.5-3.5 3.2-6.4 2.1-4-1.2-5-4-4.2-6.8z'
      className='ctoText'
    />
    <circle className='st1cto' cx='112' cy='112.6' r='70' />
    <path
      className='st1cto'
      d='M182 56c31.2 38.7 25.1 95.4-13.6 126.6S73 207.7 41.9 169c-3.3-4.1-6.2-8.4-8.8-13'
    />
    <path
      className='st1cto logoCto'
      transform='rotate(-53.871 165.09210976 39.85527033)'
      d='M163.1 37.9h4v4h-4z'
    />
    <path
      className='st1cto logoCto'
      transform='matrix(.9789 -.2042 .2042 .9789 -26.2451 7.6435)'
      d='M21.9 129h4v4h-4z'
    />
    <path
      transform='rotate(-45 112.55399322 75.33653601)'
      className='st2cto'
      d='M110.1 72.9h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 73.12663073 114.88104955)'
      className='st2cto'
      d='M70.7 112.4h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 151.90700008 114.63182057)'
      className='st2cto'
      d='M149.5 112.2h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 112.53097514 154.0455701)'
      className='st2cto'
      d='M110.1 151.6h4.9v4.9h-4.9z'
    />
    <path
      className='st3cto'
      d='m137.8 129.7-.6-.2c-.1 0-.3 0-.3.2l-3.3 11.4v.2c0 .1.1.1.1.1l.6.2h.2c.1 0 .1-.1.1-.2L138 130v-.2c0-.1-.1-.1-.2-.1zM133.2 133.1c0-.1 0-.1-.1-.2l-.4-.5s-.1-.1-.2-.1-.1 0-.2.1l-3.4 3c-.1 0-.1.1-.1.2s0 .1.1.2l3.4 2.9.1.1c.1 0 .1 0 .2-.1l.4-.5s.1-.1.1-.2 0-.1-.1-.2l-2.7-2.3 2.7-2.3c.2.1.2 0 .2-.1zM142.5 135.4l-3.3-2.9c-.1-.1-.2-.1-.3 0l-.4.5s-.1.1-.1.2 0 .1.1.2l2.7 2.3-2.7 2.3s-.1.1-.1.2 0 .1.1.2l.4.5s.1.1.2.1.1 0 .2-.1l3.3-3c.1 0 .1-.1.1-.2-.1-.2-.1-.2-.2-.3zM101.2 97.4c.1-.4.1-.8.1-1.2 0-.4 0-.8-.1-1.1l1.3-.8c.2-.1-.1-.8-.5-1.5s-.9-1.3-1-1.2l-1.3.8c-.6-.5-1.3-.9-2-1.2v-1.5c0-.2-.7-.3-1.6-.3s-1.6.1-1.6.3v1.5c-.7.2-1.3.6-1.9 1.1l-1.3-.8c-.2-.1-.6.4-1.1 1.2s-.7 1.4-.6 1.5l1.3.8c-.1.4-.2.8-.2 1.3 0 .3 0 .7.1 1l-1.1.7c-.2.1.1.8.5 1.5s.9 1.3 1 1.2l1.3-.7c.6.5 1.3 1 2 1.2v1.5c0 .2.7.3 1.6.3s1.6-.1 1.6-.3v-1.5c.7-.2 1.4-.6 2-1.1l1.3.8c.2.1.6-.4 1.1-1.2s.7-1.4.5-1.5l-1.4-.8zm-5.1 1c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2zM113.3 137.8c6.3-1.9 11.6-10.2 11.6-10.3l1.5-2 7.4-9.2c.4-.4.7-1 .7-1.6 0-.2-.4-.5-.6-.7s-1.4.4-1.8.8l-8.3 8-.6-.7 8.7-12.3c.3-.4.6-.8.8-1.3.2-.3.2-.7.2-1.1 0-.3-.2-.5-.4-.7s-.6-.3-.9-.3c-.6.2-1.2.5-1.6 1.1l-.9 1-9.1 10.8-1.1-.9.1-.2 4.7-8.7 3.7-6.7c.3-.5 1.1-2 .8-2.8-.1-.3-.3-.5-.5-.6-.3-.2-.7-.2-1.1-.1-.6.3-1.1.9-1.4 1.6l-6.6 10.9-2.8 4.7-1.3-.6 1-2.4 5.3-13c.3-.6.4-1.4.2-2.1-.1-.3-.3-.4-.6-.5-1.4-.6-2.1 1.2-2.2 1.2l-7.6 15.3-.5.3-1.3.9c-.4.2-.9.2-1.3 0s-.7-.7-.7-1.2v-.4c0-2-.1-4.1-.4-6.1 0-.6-.3-1.2-.7-1.7-.3-.3-.8-.4-1.2-.3 0 0-1 .3-1.1 2.7l-.7 4.5-.5 3.8c0 .1-1.8 8.6-.6 13.3.2 0 2.5 10.4 11.7 7.6z'
    />
    <path
      className='st1cto'
      d='M99.5 95.2c.1-.4.2-.8.2-1.2 0-.4 0-.8-.1-1.1l1.3-.8c.2-.1-.1-.8-.5-1.5s-.9-1.3-1-1.2l-1.4.8c-.6-.5-1.3-.9-2-1.2v-1.5c0-.2-.7-.3-1.6-.3s-1.6.2-1.6.3V89c-.7.2-1.3.6-1.9 1.1l-1.3-.8c-.1-.1-.6.4-1.1 1.2s-.7 1.4-.6 1.5l1.3.8c-.1.4-.2.9-.2 1.3 0 .3 0 .7.1 1l-1.3.7c-.2.1.1.8.5 1.5s.9 1.3 1 1.2l1.3-.7c.6.5 1.3 1 2 1.2v1.5c0 .2.7.3 1.6.3s1.6-.1 1.6-.3V99c.7-.2 1.4-.6 2-1.1l1.3.8c.2.1.6-.4 1.1-1.2s.7-1.4.5-1.5l-1.2-.8zm-5.1 1c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2zM110.3 135.8c6.3-1.9 11.6-10.2 11.6-10.3l1.5-2 7.4-9.2c.4-.4.6-1 .7-1.6 0-.2-.4-.6-.6-.7-.4-.3-1.4.4-1.8.8l-8.3 8-.6-.7 8.7-12.3c.3-.4.6-.8.8-1.3.2-.3.2-.7.2-1.1 0-.3-.2-.5-.4-.7s-.6-.3-.9-.3c-.6.1-1.2.5-1.6 1l-.9 1-9.1 10.8-1.1-.9.1-.2 4.7-8.7 3.7-6.7c.3-.5 1.1-2 .8-2.8-.1-.3-.3-.5-.5-.6-.3-.2-.7-.2-1.1-.1-.6.3-1.1.9-1.4 1.6l-6.6 10.9-2.8 4.8-1.3-.6 1-2.4 5.3-12.9c.3-.7.4-1.4.2-2.1-.1-.3-.3-.5-.6-.5-1.4-.5-2.1 1.2-2.2 1.2l-7.6 15.3-.5.3-1.3.9c-.4.2-.9.2-1.3 0s-.7-.7-.7-1.2v-.5c0-2-.1-4.1-.4-6.1 0-.6-.3-1.2-.7-1.7-.3-.3-.8-.4-1.2-.3 0 0-1 .3-1.1 2.7l-.6 4.6-.5 3.8c0 .1-1.8 8.6-.6 13.3.1-.1 2.4 10.2 11.6 7.5z'
    />
    <path
      className='st4cto'
      d='M133.7 105.2c1.3.3 2.1 1.7 1.7 3 0 .1-.1.2-.1.3M134.9 103.4c2 .5 3.2 2.6 2.7 4.6 0 .2-.1.3-.2.5M95.3 134.5c-1.3-.2-2.2-1.4-2.1-2.7 0-.1 0-.2.1-.3M94.4 136.4c-2.1-.3-3.5-2.2-3.2-4.3 0-.2.1-.3.1-.5'
    />
    <path
      className='st6cto'
      d='m136.8 129.3-.6-.2c-.1 0-.3 0-.3.2l-3.3 11.4v.2c0 .1.1.1.2.1l.6.2h.2c.1 0 .1-.1.1-.1l3.3-11.5v-.2s-.1 0-.2-.1zM132.3 132.8c0-.1 0-.1-.1-.2l-.4-.5s-.1-.1-.2-.1-.1 0-.2.1l-3.4 3s-.1.1-.1.2 0 .1.1.2l3.4 2.9.1.1c.1 0 .1 0 .2-.1l.4-.5s.1-.1.1-.2 0-.1-.1-.2l-2.7-2.3 2.7-2.3c.2 0 .2-.1.2-.1zM141.6 135.1l-3.4-3c-.1-.1-.2-.1-.3 0l-.4.5s-.1.1-.1.2 0 .1.1.2l2.7 2.3-2.7 2.3s-.1.1-.1.2 0 .1.1.2l.4.5s.1.1.2.1.1 0 .2-.1l3.3-2.9c.1 0 .1-.1.1-.2l-.1-.3z'
    />
  </svg>
)

export default CTOServices
