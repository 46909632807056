import * as React from 'react'

export const AppDevelopment = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    viewBox='0 0 210 210'
    xmlSpace='preserve'
  >
    <style>
      {
        '.st0appDev{fill:none;stroke:var(--color-badge-text);stroke-width:1.25;stroke-miterlimit:10}.st1appDev{enable-background:new}.st2appDev{fill:var(--color-badge-text);}.st3appDev{fill:none;stroke:#3B378D;stroke-width:1;stroke-miterlimit:9.9999}.st5appDev{fill:none;stroke:var(--color-badge-text);stroke-width:1.25;stroke-linecap:round;stroke-linejoin:round}'
      }
    </style>
    <path
      className='st0appDev'
      d='M134.6 47.7c35.8 14.6 52.9 55.5 38.3 91.3-14.6 35.8-55.5 52.9-91.3 38.3-35.8-14.6-52.9-55.5-38.3-91.3 2.1-5.1 4.8-10 8-14.5'
    />
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M4.2 102.2l.2-2 14.8-3.7-.2 2-4.5 1-.6 5.7 4.2 2-.2 1.8-13.7-6.8zm8.3 2.3l.5-4.7-5 1.1c-.8.2-2.1.4-2.1.4s1.2.5 1.9.9l4.7 2.3z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M6.6 87.6l1.7-5.7c.4-1.4 1.2-2.4 2.3-2.9.8-.4 1.8-.5 2.9-.2 2.5.7 3.6 2.6 2.7 5.6l-1.1 3.7 5.8 1.7-.5 1.7-13.8-3.9zm7.1.3l1.1-3.6c.6-1.9-.1-3.1-1.7-3.6-1.7-.5-2.8.3-3.3 2l-1.1 3.7 5 1.5z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M12.4 70.6l2.6-5.3c.7-1.3 1.6-2.2 2.7-2.4.9-.2 1.9-.1 2.9.3 2.4 1.2 3.1 3.2 1.7 6l-1.7 3.5 5.4 2.7-.8 1.6-12.8-6.4zm6.9 1.5l1.7-3.4c.9-1.8.4-3.1-1.1-3.8-1.5-.8-2.8-.2-3.6 1.4l-1.7 3.5 4.7 2.3z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M25.7 48.2l3.4-3.9c2.8-3.2 6.4-2.8 9.6 0 3.2 2.8 3.9 6.3 1 9.5l-3.3 3.8-10.7-9.4zm12.9 4.7c2.2-2.5 1.5-4.9-1-7.2-2.5-2.2-5-2.8-7.2-.3l-2.2 2.5 8.4 7.4 2-2.4z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M39.2 34.1l7.9-6.3 1 1.2-6.5 5.1 2.8 3.5 5.9-4.7.9 1.2-5.9 4.7 3.1 3.9 6.7-5.3 1 1.3-8.1 6.4-8.8-11z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M53.1 23.6l1.7-.9 8.1 7.8 1.4 1.4s-.3-1.3-.4-2l-1.9-11 1.7-.9 2.2 15-1.7 1-11.1-10.4z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M70.4 15.1l9.6-3.2.5 1.5-7.9 2.7 1.4 4.3 7.2-2.4.5 1.4-7.2 2.4 1.6 4.8 8.1-2.7.5 1.6-9.8 3.3-4.5-13.7z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M88 10.1l1.8-.3 1.9 12.5 7.4-1.1.2 1.6-9.2 1.4L88 10.1z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M103.3 15.3c.1-4.3 2.9-7.4 7.2-7.3 4.3.1 6.9 3.4 6.8 7.6s-2.9 7.4-7.2 7.3c-4.3-.1-6.9-3.3-6.8-7.6zm12.1.3c.1-3.4-1.7-5.9-5-6-3.4-.1-5.2 2.4-5.3 5.8-.1 3.4 1.7 5.9 5 6 3.4 0 5.3-2.5 5.3-5.8z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M125.6 9.7l5.8 1.3c1.5.3 2.5 1.1 3 2.1.4.8.6 1.8.4 2.9-.6 2.6-2.4 3.8-5.4 3.1l-3.8-.8-1.3 5.9-1.8-.4 3.1-14.1zm.3 7.1l3.7.8c2 .4 3.1-.4 3.5-2 .4-1.7-.5-2.8-2.3-3.2l-3.8-.8-1.1 5.2z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M143.2 14.2l2.1.9-.5 12.7 9-9.1 2.1.9-5.7 13.1-1.6-.7 4.7-10.8s-.8.9-1.3 1.4l-7.6 7.6-1.3-.6.4-10.7c0-.8.1-1.9.1-1.9L139 28l-1.5-.6 5.7-13.2z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M163.6 24.2l8.3 5.8-.9 1.3-6.8-4.7-2.6 3.7 6.2 4.3-.9 1.2-6.2-4.3-2.9 4.1 7 4.9-.9 1.4-8.5-5.9 8.2-11.8z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M178.4 35.4l1.3 1.4-2.5 11.6c-.2.9-.6 2-.6 2l8.4-8.3 1.2 1.2-10.2 10-1.3-1.3 2.5-11.7.5-2-8.4 8.2-1.2-1.2 10.3-9.9z'
      />
    </g>
    <g className='st1appDev'>
      <path
        className='st2appDev'
        d='M191.2 49.4l6.3 9.4-1.3.9-2.7-3.9-10.5 7-1-1.5 10.5-7.1-2.7-3.9 1.4-.9z'
      />
    </g>
    <path
      className='st0appDev'
      d='M197.9 107.5c.1 1.8.1 3.7.1 5.5-.2 49.7-40.6 89.8-90.4 89.6-32.9-.1-63.1-18.2-78.7-47.1'
    />
    <path
      transform='rotate(-19.144 193.00457945 83.11208675)'
      className='st2appDev'
      d='M191 81.1h4v4h-4z'
    />
    <path
      transform='matrix(.9761 -.2175 .2175 .9761 -28.2523 7.5334)'
      className='st2appDev'
      d='M18.1 130.1h4v4h-4z'
    />
    <path
      transform='rotate(-45 108.94126356 75.31352863)'
      className='st3appDev'
      d='M106.5 72.9h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 69.511002 114.85104297)'
      className='st3appDev'
      d='M67.1 112.4H72v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 148.22077268 114.67255596)'
      className='st3appDev'
      d='M145.8 112.2h4.9v4.9h-4.9z'
    />
    <path
      transform='rotate(-45 108.9184869 154.02266273)'
      className='st3appDev'
      d='M106.5 151.6h4.9v4.9h-4.9z'
    />
    <path
      d='M112.6 146.5l-21.5-6.9c-1.2-.4-1.9-1.7-1.5-3l14-42.4c.4-1.3 1.7-1.9 3-1.5l21.5 6.9c1.2.4 1.9 1.7 1.5 3l-14 42.4c-.4 1.2-1.7 1.9-3 1.5z'
      fill='#a5a5ff'
    />
    <path
      className='st2appDev'
      d='M112 105.9l-.5-.4c-.1-.1-.3-.1-.4.1l-7.1 10.5v.2c0 .1.1.1.1.2l.5.4h.2c.1 0 .1-.1.2-.1l7.1-10.5c0-.1.1-.1.1-.2-.1-.1-.1-.2-.2-.2zM106.3 107.9v-.2l-.3-.6c0-.1-.1-.1-.1-.1h-.2l-4.3 1.9c-.1 0-.1.1-.1.2v.2l2.4 4.1.1.1h.2l.6-.3c.1 0 .1-.1.1-.2v-.2l-2-3.2 3.5-1.4c0-.2 0-.3.1-.3zM114.9 113.2l-2.4-4.1c-.1-.1-.2-.1-.3-.1l-.6.3c-.1 0-.1.1-.1.2v.2l2 3.2-3.5 1.4c-.1 0-.1.1-.1.1v.2l.3.6c0 .1.1.1.2.1h.2l4.3-1.9c.1 0 .1-.1 0-.2.1.2.1.1 0 0z'
    />
    <path
      className='st5appDev'
      d='M103.2 83.4l24.2 7.8c1.3.4 1.9 1.8 1.5 3L114 140.1c-.4 1.3-1.8 1.9-3 1.5l-24.2-7.8c-1.3-.4-1.9-1.8-1.5-3l14.9-45.9c.4-1.3 1.7-1.9 3-1.5z'
    />
    <path
      className='st5appDev'
      d='M125.7 96.7l-13.3 41.1c-.3.8-1.1 1.3-1.9 1l-21.4-6.9c-.8-.3-1.3-1.1-1-1.9l13.3-41.1c.3-.8 1.1-1.3 1.9-1l21.4 6.9c.8.2 1.2 1 1 1.9zM111 88.3l7.2 2.3'
    />
  </svg>
)

export default AppDevelopment
