import React from 'react'
import styled, { useTheme } from 'styled-components'
import Typing from 'react-typing-animation'
import { useInView } from 'react-intersection-observer'
import Grid from '@components/ViewGridComponent'

// Components
import SectionComponent from '@components/SectionComponent'
import SpacerComponent from '@components/SpacerComponent'
import {
  Paragraph,
  Highlight,
  Title1,
  VerticalHeading,
} from '@components/Typography'
import useIsMobile from '@hooks/useIsMobile'

type ApproachHeroProps = {}
// @ts-expect-error -- types are wrong, does export
const Cursor = styled(Typing.Cursor)`
  color: var(--color-text);
  padding-left: 0.125rem;
  font-size: 3rem;
`

const IntroText = () => {
  const lines = [
    ' people with a voice',
    ' people who need a voice',
    ' the caring',
    ' the daring',
    ' the disruptors',
    ' the rulebreakers',
  ]

  const count = lines[0].replace('people who', '').length

  const config = {
    delay: 1200,
    speed: 50,
  }

  const renderTypingConfig = count => (
    <>
      <Typing.Delay ms={config.delay} />
      <Typing.Speed ms={config.speed} />
      <Typing.Reset count={count} delay={config.delay} />
    </>
  )

  const { inView, ref } = useInView()
  const isMobile = useIsMobile()

  const renderTypingAnimation = () => (
    <Title1>
      <Highlight>{lines[0]}</Highlight>
      {renderTypingConfig(count)}
      <Highlight>{lines[1]}</Highlight>
      {renderTypingConfig(lines[1].length)}
      <Highlight>{lines[2]}</Highlight>
      {renderTypingConfig(lines[2].length)}
      <Highlight>{lines[3]}</Highlight>
      {renderTypingConfig(lines[3].length)}
      <Highlight>{lines[4]}</Highlight>
      {renderTypingConfig(lines[4].length)}
      <Highlight>{lines[5]}</Highlight>
      {renderTypingConfig(lines[5].length)}
      <Highlight>{lines[6]}</Highlight>
    </Title1>
  )

  const [animation, resetTypingAnimation] = React.useState(
    renderTypingAnimation()
  )

  return (
    <>
      <StyledTitle centered={!isMobile} ref={ref} aria-hidden='true'>
        We create tech for <br />{' '}
        {inView && (
          <Typing
            cursor={<Cursor />}
            loop
            onFinishedTyping={() => resetTypingAnimation({ ...animation })}
          >
            {animation}
          </Typing>
        )}
      </StyledTitle>
      <h1 className='visually-hidden'>{`We create tech for ${lines.join(
        ','
      )}.`}</h1>
    </>
  )
}

const ApproachHero: React.FC<ApproachHeroProps> = () => {
  const isMobile = useIsMobile()

  return (
    <SectionComponent>
      <Grid.Item columnStart={1} columnEnd={1} justifySelfStart>
        <VerticalHeading hasVerticalSpacer>What we do</VerticalHeading>
      </Grid.Item>
      <Grid.Item column={isMobile ? '3 / 13' : '3 / 11'} justifySelfCenter>
        <IntroTextContainer>
          <IntroText />
          <SpacerComponent bottom='lg' />
          <Paragraph
            css={`
              text-align: ${isMobile ? 'left' : 'center'};
            `}
          >
            Rewriting the rules on how the world builds tech, one project at a
            time.
          </Paragraph>
        </IntroTextContainer>
      </Grid.Item>
    </SectionComponent>
  )
}

const IntroTextContainer = styled.div`
  min-height: 16rem;
  height: max(16rem, 100%);
`

const StyledTitle = styled(Title1)`
  min-height: 12rem;
`

export default ApproachHero
