import { motion } from 'framer-motion'
import React, { useState } from 'react'
import styled, { ThemeProvider, useTheme } from 'styled-components/macro'

import SectionComponent from '@components/SectionComponent'
import SpacerComponent from '@components/SpacerComponent'
import {
  Heading,
  Heading2,
  Highlight,
  Paragraph,
  VerticalHeading,
} from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import { blueTheme } from '@src/themes'

import {
  appDevelopment,
  ctoServices,
  marketingStrategy,
  productStrategy,
  projectManagement,
  userExperience,
  userInterface,
  webDevelopment,
} from '@components/BadgeComponent'
import { FadeIn, StaggerAnimation } from '@components/Animations'
import useIsMobile from '@hooks/useIsMobile'
import HeadingSectionComponent from '@components/HeadingSectionComponent'

// images

type SolutionSrc = {
  white: string
  dark: string
}
export type Solution = {
  id: string
  src: React.ReactNode
  title: string
  body: string
}

const solutions: Solution[] = [
  {
    id: 'web',
    src: webDevelopment,
    title: 'Web Development',
    body: 'Beautifully-realised websites that are both robust and high performing. Built in both XXX and XXX, supported by AWS infrastructure.',
  },
  {
    id: 'app',
    src: appDevelopment,

    title: 'App Development',
    body: 'We can help you bring your idea to life with native and progressive web app development services.',
  },
  {
    id: 'ux',
    src: userExperience,

    title: 'User Experience (UX) Design',
    body: 'We’ll help you shape all interactions with your users, ensuring their needs are met and their experience is seamless. ',
  },
  {
    id: 'ui',
    src: userInterface,
    title: 'User Interaction (UI) Design',
    body: 'We’ll ensure your product is intuitive by designing it with look, feel and interactivity at the heart of our work. ',
  },
  {
    id: 'product',
    src: productStrategy,

    title: 'Product Strategy',
    body: 'We’ll help you shape your product, its features and how your users will engage and ultimately benefit from using it. ',
  },
  {
    id: 'cto',
    src: ctoServices,

    title: 'CTO Services',
    body: 'As tech partners, we can provide in-house CTO capability, providing that extra level of strategic thinking. ',
  },
]

const Solutions: React.FC = () => {
  const isMobile = useIsMobile()

  return (
    <SectionComponent>
      <HeadingSectionComponent
        verticalHeading='Our Approach'
        heading={
          <FadeIn>
            <Heading>
              We are <Highlight>solutions focused</Highlight>.
            </Heading>
          </FadeIn>
        }
        content={[
          <FadeIn>
            <SpacerComponent bottom='sm' />
            <Paragraph size='md'>
              And by that, we mean we don't just build a product and leave.
            </Paragraph>
            <SpacerComponent bottom='sm' />
            <Paragraph size='md'>
              We’re a collaborative group that will bring your project to life
              through co-design and a sense of fun.
            </Paragraph>
            <SpacerComponent bottom='sm' />
            <Paragraph size='md'>
              Think of us as partners and your in-house team who are invested in
              helping you to not only build your project, but to launch it and
              drive positive impact.
            </Paragraph>
          </FadeIn>,
        ]}
      />

      <SpacerComponent bottom='xl2' />
      <Grid.Row>
        {solutions.map((sol, i) => {
          const isEven = i % 2 > 0
          const shouldOffsetBottom = isMobile ? !isEven : i === 0
          const shouldOffsetTop = isMobile ? isEven : [2, 5].includes(i + 1)
          const Badge = sol.src
          return (
            <Grid.Item column={isMobile ? 'span 6' : 'span 4'}>
              <FadeIn
                css={`
                  display: flex;
                  justify-content: center;
                `}
              >
                <SolutionsIcon
                  key={sol.id}
                  whileHover={{
                    scale: 1.1,
                  }}
                  shouldOffsetTop={shouldOffsetTop}
                  shouldOffsetBottom={shouldOffsetBottom}
                >
                  <Badge />
                </SolutionsIcon>
              </FadeIn>
            </Grid.Item>
          )
        })}
      </Grid.Row>
    </SectionComponent>
  )
}

type SolutionsIconProps = {
  shouldOffsetTop?: boolean
  shouldOffsetBottom?: boolean
}

const SolutionsIcon = styled(motion.div)<SolutionsIconProps>`
  width: 80%;
  background-color: transparent;
  color: ${props => props.theme.colors.text};
  ${({ theme, shouldOffsetTop }) =>
    shouldOffsetTop && `padding-top: ${theme.spacing.xl}`};
  ${({ theme, shouldOffsetBottom }) =>
    shouldOffsetBottom && `padding-bottom: ${theme.spacing.xl}`};
`

export default Solutions
