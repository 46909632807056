import * as React from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

// Hooks
import { useTheme } from 'styled-components/macro'
import useIsMobile from '@hooks/useIsMobile'

// Components
import SectionComponent from '@components/SectionComponent'
import { FadeIn } from '@components/Animations'
import { Heading } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import LemonAnimation from '@components/AnimatedLemonDudeComponent'
import { useInView } from 'react-intersection-observer'

const RewritingRules: React.FC = () => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  const { inView, ref } = useInView()

  return (
    <SectionComponent ref={ref}>
      <Grid.Item
        column={isMobile ? '2 / 12' : '2 / 5'}
        order={1}
        alignSelfStart
        css={`
          padding-bottom: ${isMobile ? theme.spacing.xl : 'initial'};
        `}
      >
        <LemonAnimation shouldAnimate={inView} />
      </Grid.Item>
      <Grid.Item column={isMobile ? '2 / 12' : '6 / 13'} order={2}>
        <FadeIn>
          <Heading
            css={`
              padding-bottom: ${theme.spacing.sm};
              text-align: ${isMobile ? 'center' : 'left'};
            `}
          >
            Rewriting the rules on how the world builds tech, one project at a
            time.
          </Heading>
        </FadeIn>
      </Grid.Item>
    </SectionComponent>
  )
}

export default RewritingRules
